import type { IDtoBFFProductCategoryV2 } from "~/typings/api/selections";
import type { ICategory } from "~/typings/categories";

const NEW_PRODS_SECTION_TERM = "новинк";

const findNewProdsSection = (data: IDtoBFFProductCategoryV2[] | ICategory[]): IDtoBFFProductCategoryV2 | ICategory | null => {
  for (let i = 0; i < data.length; i++) {
    if (data[i].name.toLowerCase().includes(NEW_PRODS_SECTION_TERM)) {
      return data[i];
    }
    if (data[i].children) {
      const found = findNewProdsSection(data[i].children);
      if (found) return found;
    }
  }
  return null;
};

const extractIds = (data: IDtoBFFProductCategoryV2 | ICategory): number[] => {
  const result = [];
  result.push(Number(data.id));
  if (data.children.length) {
    for (let i = 0; i < data.children.length; i++) {
      result.push(...extractIds(data.children[i]));
    }
  }

  return result;
};

export const findNewProdsSectionIds = (data?: IDtoBFFProductCategoryV2[] | ICategory[]): number[] => {
  if (data === undefined) return [];
  const section = findNewProdsSection(data);
  if (section === null) return [];
  return extractIds(section);
};
