<script setup lang="ts">
import { vElementVisibility } from "@vueuse/components";
import VText from "@magnit/core/src/components/VText/VText.vue";
import VWrapper from "@magnit/core/src/components/VWrapper/VWrapper.vue";
import VAppLink from "@magnit/core/src/components/VAppLink/VAppLink.vue";
import VIcon from "@magnit/icons/src/components/VIcon/VIcon.vue";
import VButton from "@magnit/core/src/components/VButton/VButton.vue";
import type { IPartnersOffer } from "~/typings/api/partners";
import { collectPartnersOffersPayload } from "~/utils/analytics";

const { send } = useAnalytics();

const { requestPartnersOffers } = partnersApi();
const { status, data } = await requestPartnersOffers();

const slides = computed(() => (data.value || []).slice(0, 12));

const onVisibility = (v: boolean) => v && send("Sections:Bonuses:View");
const onSlideVisibility = (v: boolean, item: IPartnersOffer, idx: number) => {
  if (v) {
    send("Sections:Bonuses:Item:View", collectPartnersOffersPayload({ item, idx }));
  }
};
const onSlideClick = (item: IPartnersOffer, idx: number) => {
  send("Sections:Bonuses:Item:Click", collectPartnersOffersPayload({ item, idx }));
};

const partnersRoute = Routes.Partners;
</script>

<template>
  <SectionsPartnersOffersSkeleton v-if="status === 'pending' || !slides?.length" />
  <section v-else-if="slides?.length" v-element-visibility="onVisibility" class="partners-offers">
    <div class="partners-offers__wrapper">
      <VWrapperLocal class="partners-offers__header">
        <VText :font="{ xs: 'headline-small', l: 'headline-large' }">
          Бонусы от партнёров
        </VText>
        <VAppLink :to="partnersRoute">
          <VButton theme="tertiary" :square="{ xs: true, ml: false }" icon-position="right">
            <template #icon>
              <VIcon name="24-outline-right-corner" size="24" />
            </template>
            <VText font="body-large-accent">
              Посмотреть всё
            </VText>
          </VButton>
        </VAppLink>
      </VWrapperLocal>
      <VWrapper :indent="{ l: '32px' }">
        <AppSlider
          loop
          autoplay
          :breakpoints="{
            xs: {
              slidesPerView: 'auto',
              navigation: false,
              spaceBetween: 8,
              slidesOffsetAfter: 12,
              slidesOffsetBefore: 12,
            },
            s: {
              slidesPerView: 'auto',
              spaceBetween: 12,
              slidesOffsetAfter: 20,
              slidesOffsetBefore: 20,
            },
            m: {
              slidesPerView: 'auto',
              spaceBetween: 20,
              slidesOffsetAfter: 32,
              slidesOffsetBefore: 32,
            },
            l: {
              slidesPerView: 4,
              spaceBetween: 24,
              slidesOffsetAfter: 0,
              slidesOffsetBefore: 0,
              navigation: true,
            },
            xl: {
              slidesPerView: 6,
              spaceBetween: 24,
              navigation: true,
            },
          }"
        >
          <AppSliderSlide
            v-for="(item, idx) in slides"
            :key="item.id"
            :size="{ xs: 6, m: 4, ml: 3, xl: 2 }"
          >
            <PartnersCard
              :partner="item"
              :position="idx"
              @on-click="onSlideClick(item, idx)"
              @on-visibility="(arg: boolean) => onSlideVisibility(arg, item, idx)"
            />
          </AppSliderSlide>
        </AppSlider>
      </VWrapper>
    </div>
  </section>
</template>

<style scoped lang="postcss">
.partners-offers {
  --pl_-slider-offsets: 64px;
  --pl-slider-gaps: 72px;

  /* min-height прописаны чтобы уменьшить прыжки страницы пока картинки рендерит */
  min-height: 444px;

  @media (--pl-viewport-from-m) {
    min-height: 388px;
  }

  @media (--pl-viewport-from-ml) {
    min-height: 397px;
  }

  @media (--pl-viewport-from-l) {
    --pl-slider-gaps: 72px;

    min-height: 446px;
    padding-top: 48px;
    padding-bottom: 16px;

    :deep(.slider-navigation) {
      top: calc((100vw - var(--pl-slider-gaps) - var(--pl_-slider-offsets)) / 8);
    }
  }

  @media (--pl-viewport-from-xl) {
    --pl-slider-gaps: 120px;

    :deep(.slider-navigation) {
      top: calc((min(1280px, 100vw) - var(--pl-slider-gaps) - var(--pl_-slider-offsets)) / 12);
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: var(--pl-unit-x8);
    padding-bottom: var(--pl-unit-x2);

    @media (--pl-viewport-from-l) {
      padding-bottom: 18px;
    }
  }

  &__offer {
    display: flex;
    flex-direction: column;
    position: relative;

    &-logo {
      padding: var(--pl-unit-x2);
      border-radius: var(--pl-bottomsheet-radius-l);
      background: var(--pl-surface-muted-default);
      display: flex;
      justify-content: center;
      align-items: center;

      :deep(img) {
        max-width: 100%;
      }
    }

    &-description {
      padding: var(--pl-unit-x1);
    }
  }
}
</style>
