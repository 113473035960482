import { urls } from "~/api/config";
import type { IDtoBFFProductCategoriesV2 } from "~/typings/api/selections";
import { getCachedData } from "~/utils/cached-data";

export default () => {
  const storesStore = useStoresStore();

  const requestSelections = () => {
    const code = computed(() => storesStore.requestShopCode);
    const url = computed(() => `${urls.selections.store}${code.value}`);

    return useTransport<IDtoBFFProductCategoriesV2, IDtoBFFProductCategoriesV2>(url, {
      query: computed(() => ({
        storetype: String(storesStore.requestShopType),
        catalogtype: String(API_CATALOG_TYPE),
      })),
      key: `request-selections-${String(storesStore.requestShopType)}-${String(API_CATALOG_TYPE)}`,
      getCachedData: getCachedData,
      lazy: true,
    });
  };

  return {
    requestSelections,
  };
};
