<script lang="ts" setup>
import { CatalogBFFSortV2Order, CatalogBFFSortV2Type } from "~/typings/api/goods";

const { requestProducts } = goodsApi();
const { weekProductsCategory } = usePublicConfig();

const payload = computed(() => {
  return {
    categories: weekProductsCategory.value ? [parseInt(weekProductsCategory.value)] : [],
    sort: {
      order: CatalogBFFSortV2Order.desc,
      type: CatalogBFFSortV2Type.popularity,
    },
    pagination: {
      limit: 12,
      offset: 0,
    },
  };
});

const { data, status } = await requestProducts(payload, "section-week-products-goods-catalog");

const catalogRoute = `${Routes.Catalog}?onlyDiscount=true`;
const adfoxPayload = { id: "adfox_17156806391233746", p1: "dcetd", p2: "htnq" };
</script>

<template>
  <SectionsWeekProductsSkeleton v-if="status === 'pending'" />
  <AppProductsSlider
    v-else-if="data?.goods.length"
    :products="data.goods"
    :adfox-payload="adfoxPayload"
    :link="catalogRoute"
    title="Товары недели"
    class="week-products"
    stat-prefix="Sections:WeeklyProduct"
  />
</template>

<style lang="postcss" scoped>
.week-products {
  /* min-height прописаны чтобы уменьшить прыжки страницы пока картинки рендерит */
  min-height: 290px;

  @media (--pl-viewport-from-l) {
    min-height: 350px;
  }

  @media (--pl-viewport-from-xl) {
    min-height: 330px;
  }
}
</style>
