<script lang="ts" setup>
import { findNewProdsSectionIds } from "~/utils/selections";
import { CatalogBFFSortV2Order, CatalogBFFSortV2Type } from "~/typings/api/goods";

const { requestSelections } = selectionsApi();
const { requestProducts } = goodsApi();

const { data } = await requestSelections();
const { goods } = useCategoriesStore();

// Категория новинок может быть в подборках или категориях, делаем универсально.
const sectionIds = computed(() => {
  const selections = data.value?.items ? findNewProdsSectionIds(data.value.items) : [];
  if (selections.length) return selections;

  return findNewProdsSectionIds(toValue(goods));
});

const payload = computed(() => {
  return {
    categories: sectionIds.value,
    sort: {
      order: CatalogBFFSortV2Order.desc,
      type: CatalogBFFSortV2Type.popularity,
    },
    pagination: {
      limit: NEW_PRODUCTS_LIMIT,
      offset: 0,
    },
  };
});

const { data: dataProducts, status } = await requestProducts(payload, getObjectHash(payload.value));
const adfoxPayload = { id: "adfox_1742288371968746", p1: "dcetc", p2: "htnq" };
</script>

<template>
  <SectionsNewProductsSkeleton v-if="['initial', 'pending'].includes(status)" />
  <AppProductsSlider
    v-else-if="dataProducts?.goods.length"
    :products="dataProducts?.goods"
    :adfox-payload="adfoxPayload"
    title="Новинки"
    class="new-products"
    with-background
    stat-prefix="Sections:New"
    slider-test-id="new-products"
  />
</template>

<style lang="postcss" scoped>
.new-products {
  /* min-height прописаны чтобы уменьшить прыжки страницы пока картинки рендерит */
  min-height: 378px;

  @media (--pl-viewport-from-l) {
    min-height: 478px;
  }
}
</style>
