<script lang="ts" setup>
import { IPromotionType } from "~/enums/promotionsTypes";

const { requestPromotionsByType } = promoApi();
const { status, data } = await requestPromotionsByType({
  type: [IPromotionType.Special, IPromotionType.Federal],
  limit: 10,
});

const promoCatalogRoute = Routes.PromoCatalog;
const adfoxPayload = { id: "adfox_17156806391233746", p1: "dcetd", p2: "htnq" };
</script>

<template>
  <SectionsWeekProductsSkeleton v-if="status === 'pending'" />
  <AppProductsSlider
    v-else-if="data?.goods?.length"
    :products="data?.goods || []"
    :adfox-payload="adfoxPayload"
    :link="promoCatalogRoute"
    title="Товары недели"
    class="week-products"
    stat-prefix="Sections:WeeklyProduct"
  />
</template>

<style lang="postcss" scoped>
.week-products {
  /* min-height прописаны чтобы уменьшить прыжки страницы пока картинки рендерит */
  min-height: 290px;

  @media (--pl-viewport-from-l) {
    min-height: 350px;
  }

  @media (--pl-viewport-from-xl) {
    min-height: 330px;
  }
}
</style>
