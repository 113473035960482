<script lang="ts" setup>
const { send } = useAnalytics();
const { requestTicker } = sectionsApi();
const { status, data: ticker } = await requestTicker();

const onVisibility = () => {
  if (ticker.value) {
    send("Sections:Ticker:View", collectTickerPayload(ticker.value));
  }
};
const onClick = () => {
  if (ticker.value) {
    send("Sections:Ticker:Click", collectTickerPayload(ticker.value));
  }
};
</script>

<template>
  <AppTicker
    :ticker="ticker"
    :pending="status === 'pending'"
    class="sections-ticker"
    @on-visibility="onVisibility"
    @on-click="onClick"
  />
</template>

<style lang="postcss" scoped>
.sections-ticker {
  padding: var(--pl-unit-x8) 0 var(--pl-unit-x4);

  @media (--pl-viewport-from-l) {
    padding-top: 48px;
  }
}
</style>
